import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Vivre_Motion_Stream_Deck/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Stream Deck IP-CAMERA Plugin",
  "path": "/Software/Other_Platforms/Vivre_Motion_Stream_Deck/",
  "dateChanged": "2022-09-07",
  "author": "Mike Polinowski",
  "excerpt": "The Elgato Stream Deck, Loupedeck and Razer Stream Controller delivers a unique live content creation controller with endless possibilities to optimize your workflow. With the VIVRE MOTION Stream Deck IP-CAMERA Plugin App you can place the live image from your INSTAR IP Camera directly on a button and even select the section.",
  "social": "/images/Search/P_SearchThumb_Stream_Deck.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_S-Stream_Deck_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Stream Deck"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Stream Deck IP-CAMERA Plugin' dateChanged='2022-09-07' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Elgato Stream Deck, Loupedeck and Razer Stream Controller delivers a unique live content creation controller with endless possibilities to optimize your workflow. With the VIVRE MOTION Stream Deck IP-CAMERA Plugin App you can place the live image from your INSTAR IP Camera directly on a button and even select the section.' image='/images/Search/P_SearchThumb_Stream_Deck.png' twitter='/images/Search/P_SearchThumb_Stream_Deck.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Andere_Plattform/Vivre_Motion_Stream_Deck/' locationFR='/fr/Software/Other_Platforms/Vivre_Motion_Stream_Deck/' crumbLabel="Stream Deck" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "stream-deck-ip-camera-plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#stream-deck-ip-camera-plugin",
        "aria-label": "stream deck ip camera plugin permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stream Deck IP-CAMERA Plugin`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stream-deck-ip-camera-plugin"
        }}>{`Stream Deck IP-CAMERA Plugin`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#where-can-i-download-the-app"
            }}>{`Where can I download the App?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#your-cameras-rtsp-stream"
            }}>{`Your Cameras RTSP Stream`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#setup-your-camera"
            }}>{`Setup your Camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#stream-deck-interface"
            }}>{`Stream Deck Interface`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Update`}</strong>{`: `}<a parentName="p" {...{
          "href": "/en/Software/Other_Platforms/biOs_MQTT_Stream_Deck/"
        }}>{`Stream Deck biOs MQTT`}</a>{`.`}</p>
    </blockquote>
    <p>{`The `}<strong parentName="p">{`Elgato Stream Deck`}</strong>{`, `}<strong parentName="p">{`Loupedeck`}</strong>{` and `}<strong parentName="p">{`Razer Stream Controller`}</strong>{` delivers a unique live content creation controller with endless possibilities to optimize your workflow. With the `}<a parentName="p" {...{
        "href": "https://vivre-motion.com/pages/ip-camera"
      }}>{`VIVRE MOTION Stream Deck IP-CAMERA Plugin`}</a>{` you can place the live image from your INSTAR IP Camera directly on a button and even select the section.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/455b36429042625214845627d6af50da/0f98f/vivre_motion_ip_camera_white_intar2_0224.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAdclNepiAH//xAAaEAACAwEBAAAAAAAAAAAAAAACAwABEwQh/9oACAEBAAEFAqdsuzIZswoDDLnb4OK7L//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAgIDAQAAAAAAAAAAAAAAAQMRAkEhMlFS/9oACAEBAAY/Ak1HsaxpfNnEkZleT7Eb3Q7W/T//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhQXFRsf/aAAgBAQABPyENhCnMq02ioY5xvSMzKPyOm2Z4xa29J//aAAwDAQACAAMAAAAQMO//xAAWEQADAAAAAAAAAAAAAAAAAAAQETH/2gAIAQMBAT8Qhj//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQIBAT8QDZ//xAAeEAACAgMAAwEAAAAAAAAAAAABEQAhMUFRYXGBof/aAAgBAQABPxB5QElYUjzbmzRhQOKrzqJh5IhFHaYvv2EJIIZwCVeoRPBArdqvw4QSTDAv2f/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/455b36429042625214845627d6af50da/e4706/vivre_motion_ip_camera_white_intar2_0224.avif 230w", "/en/static/455b36429042625214845627d6af50da/d1af7/vivre_motion_ip_camera_white_intar2_0224.avif 460w", "/en/static/455b36429042625214845627d6af50da/7f308/vivre_motion_ip_camera_white_intar2_0224.avif 920w", "/en/static/455b36429042625214845627d6af50da/e1c99/vivre_motion_ip_camera_white_intar2_0224.avif 1380w", "/en/static/455b36429042625214845627d6af50da/76ea5/vivre_motion_ip_camera_white_intar2_0224.avif 1840w", "/en/static/455b36429042625214845627d6af50da/f696c/vivre_motion_ip_camera_white_intar2_0224.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/455b36429042625214845627d6af50da/a0b58/vivre_motion_ip_camera_white_intar2_0224.webp 230w", "/en/static/455b36429042625214845627d6af50da/bc10c/vivre_motion_ip_camera_white_intar2_0224.webp 460w", "/en/static/455b36429042625214845627d6af50da/966d8/vivre_motion_ip_camera_white_intar2_0224.webp 920w", "/en/static/455b36429042625214845627d6af50da/445df/vivre_motion_ip_camera_white_intar2_0224.webp 1380w", "/en/static/455b36429042625214845627d6af50da/78de1/vivre_motion_ip_camera_white_intar2_0224.webp 1840w", "/en/static/455b36429042625214845627d6af50da/882b9/vivre_motion_ip_camera_white_intar2_0224.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/455b36429042625214845627d6af50da/e83b4/vivre_motion_ip_camera_white_intar2_0224.jpg 230w", "/en/static/455b36429042625214845627d6af50da/e41a8/vivre_motion_ip_camera_white_intar2_0224.jpg 460w", "/en/static/455b36429042625214845627d6af50da/4d836/vivre_motion_ip_camera_white_intar2_0224.jpg 920w", "/en/static/455b36429042625214845627d6af50da/89b2d/vivre_motion_ip_camera_white_intar2_0224.jpg 1380w", "/en/static/455b36429042625214845627d6af50da/5726f/vivre_motion_ip_camera_white_intar2_0224.jpg 1840w", "/en/static/455b36429042625214845627d6af50da/0f98f/vivre_motion_ip_camera_white_intar2_0224.jpg 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/455b36429042625214845627d6af50da/4d836/vivre_motion_ip_camera_white_intar2_0224.jpg",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "where-can-i-download-the-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#where-can-i-download-the-app",
        "aria-label": "where can i download the app permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where can I download the App?`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stream Deck`}</strong>{`: Open your Stream Deck Software and go to the Stream Deck Store.`}</li>
      <li parentName="ul"><strong parentName="li">{`Loupedeck`}</strong>{`: Open your Loupedeck Software and go to the Marketplace.`}</li>
    </ul>
    <p>{`Search for SMART HOME `}<strong parentName="p">{`Surveillance IP Camera`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/17d325f468b28ac782628d796b68c8af/8733b/marketplace.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACu0lEQVQ4y32SXWvcRhSGdd+L0lJnJe1KmpG0+tZK2q/sarXrtUtqN0npRaFQCr2of0AgYHu7df5NoUlpbmKb/LynzMQ1NoVevHDeOZqX55yR8cf79/z98SNvr6/588MH3ipd/6vr/+jdzYP69pZ3Nzf6TOmv2xuMIk5Ydx2TpqEejWiqirqqqMpS+/rOP6q1L6mbKXVVMxk3LBcL/Y3h9AecnJ7y237PxcUF5+fnWldXV1xe7u697j3oq3q/37Pb7bi4vNT12dkZhvQEzXhM0zTEcYwQQmsyq4niIY7j4nkevu/juq7ueZ4gCAKacU2eZ0RRRBgOKcsSQwpJlmUIT+gLg8GAJ70DXgxes6leMJk3RFGsL223W8bjMVEU4Lg2TXREOz2mGY/w3IEG0oF5nmsCRSKlxLYtuuB7jpYnfP3smNVqRZqmtG3L4eEhq803pHlFGU9ZTFcs2jVpMSPLSwxFplBVugpThJZl4fg20hdEw0gTflqFhx9EBO0ev/4ZVwwQnoWXniIXb0jKTgVKHahGUpSO4+jA7TTlsFtSljlS9InDhG5yxHrTkWcJTt/k2a+vOPnxF9qnU4ahT5omGDIIqUYjiqJ4RJinMfPZlHZzwnz1kjBMGJUjum7NZHHKMBmTT56yXG/YHn3LtH1OVtQYgX6dQo+cJAlhGGKaJuo8kA5B9QN++wYRFPjSRcghYnaOGP2EcAdIz0YWzxGL34mLFYZ/9yjqN1CEUgh6ps16nrHulhTZkKF8guP0MU1L01u9L7DMr7As+5PMA3oHnxOGPoYfRhR5rsnUDtXy1aUkjqlGBfXsmHL2EseVWJaJbdvYdv9O9r03LVtnGH6ckWeZNupB+v2+DpQywHMs3Ow7xHxH342xrN6DkMdSa9KBQZKTZ6k2qqFHsiz9gR6n9yXWwWf3Yar3f4H/AL6cyqXSNgIRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/17d325f468b28ac782628d796b68c8af/e4706/marketplace.avif 230w", "/en/static/17d325f468b28ac782628d796b68c8af/d1af7/marketplace.avif 460w", "/en/static/17d325f468b28ac782628d796b68c8af/7f308/marketplace.avif 920w", "/en/static/17d325f468b28ac782628d796b68c8af/e1c99/marketplace.avif 1380w", "/en/static/17d325f468b28ac782628d796b68c8af/05b63/marketplace.avif 1495w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/17d325f468b28ac782628d796b68c8af/a0b58/marketplace.webp 230w", "/en/static/17d325f468b28ac782628d796b68c8af/bc10c/marketplace.webp 460w", "/en/static/17d325f468b28ac782628d796b68c8af/966d8/marketplace.webp 920w", "/en/static/17d325f468b28ac782628d796b68c8af/445df/marketplace.webp 1380w", "/en/static/17d325f468b28ac782628d796b68c8af/4a9a8/marketplace.webp 1495w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/17d325f468b28ac782628d796b68c8af/81c8e/marketplace.png 230w", "/en/static/17d325f468b28ac782628d796b68c8af/08a84/marketplace.png 460w", "/en/static/17d325f468b28ac782628d796b68c8af/c0255/marketplace.png 920w", "/en/static/17d325f468b28ac782628d796b68c8af/b1001/marketplace.png 1380w", "/en/static/17d325f468b28ac782628d796b68c8af/8733b/marketplace.png 1495w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/17d325f468b28ac782628d796b68c8af/c0255/marketplace.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "your-cameras-rtsp-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#your-cameras-rtsp-stream",
        "aria-label": "your cameras rtsp stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your Cameras RTSP Stream`}</h4>
    <p>{`Every INSTAR HD/FullHD/WQHD IP Camera supports the RTSP streaming protocol. The Real Time Streaming Protocol (RTSP) is an application-level network protocol designed for multiplexing and packetizing multimedia transport streams (such as interactive media, video and audio) over a suitable transport protocol.`}</p>
    <p><strong parentName="p">{`Video Streaming`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
        }}>{`IN-9408 WQHD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9020_HD/Video_Streaming/"
        }}>{`IN-9020 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9010_HD/Video_Streaming/"
        }}>{`IN-9010 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9008_HD/Video_Streaming/"
        }}>{`IN-9008 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
        }}>{`IN-8015 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8003_HD/Video_Streaming/"
        }}>{`IN-8003 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8001_HD/Video_Streaming/"
        }}>{`IN-8001 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-7011_HD/Video_Streaming/"
        }}>{`IN-7011 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5907_HD/Video_Streaming/"
        }}>{`IN-5907 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5905_HD/Video_Streaming/"
        }}>{`IN-5905 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6014_HD/Video_Streaming/"
        }}>{`IN-6014 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/Video_Streaming/"
        }}>{`IN-6012 HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6001_HD/Video_Streaming/"
        }}>{`IN-6001 HD`}</a></li>
    </ul>
    <h4 {...{
      "id": "setup-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setup-your-camera",
        "aria-label": "setup your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup your Camera`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Step 1`}</strong>{`: Open the Camera's Web-Interface (e.g. via the IP in your Web-Browser or Smartphone Browser App) and open the `}<strong parentName="li">{`IP Configuration`}</strong>{` to find out your camera's `}<strong parentName="li">{`RTSP Port`}</strong>{` (default `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`554`}</code>{`). Also make sure that the `}<strong parentName="li">{`RTSP Authentication`}</strong>{` is active - `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Network/IP_Configuration/"
        }}>{`WQHD Models`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
        }}>{`Full HD Models`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/720p_Series/Network/IP_Configuration/"
        }}>{`HD Models`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Step 2`}</strong>{`: As described in the video streaming articles above your camera offers 3 RTSP streams with different resolution. It is recomended to use the `}<strong parentName="li">{`Secondary Stream`}</strong>{` that is optimized to the Stream Deck/Loupedeck.`}</li>
      <li parentName="ul"><strong parentName="li">{`Step 3`}</strong>{`: With the activated authentication you will have to use a user login to be able to connect to your cameras RTSP stream. You do not have to use your administrator login - `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/System/User/"
        }}>{`WQHD Models`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/System/User/"
        }}>{`Full HD Models`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/720p_Series/System/User/"
        }}>{`HD Models`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Step 4`}</strong>{`: Accept the Firewall Warning. The App establishes only a Direct/Local Connection to your Camera. If the stream is subsequently changed in the camera, the camera app or the Stream Deck/Loupedeck Software must be restarted.`}</li>
    </ul>
    <h4 {...{
      "id": "stream-deck-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#stream-deck-interface",
        "aria-label": "stream deck interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stream Deck Interface`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "526px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/2d7ab/stream_deck_interface.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC10lEQVQ4y4VUy27TUBTMn8CijySOr9/P2I6dxkkc20lTiapCSAgkNkhsUaUWdggJiQ9gwQ6Jr+IL+IhBc5sbpW0aFke+cXzmzJwz53Y0TYPreZiWJcqyxGxeIRkOEYYhRnmOOI6R5zlc1wW/1XX9UQwGA9i2Dcuy0OEPIQwI04AQOhzHge04sGxbnk3LgmFZ28SnwjRNGIZxB2iYJi4WLa6uXiLJUvSOj6D1ehjoOmwhkPs+wjiC57rwPO9RkBnBiNXp9/uI4hA3nz7g2/dbvHl7BXE2hZGk8sPQtlHHMZIs2wvo+75kR8lsS6ff1xDFPr58fY/fP65x8/Edxn/+ovj5C/bpCcLhEMXZRCYwmSBMVGc+hRDy3WKx2EgWArMyxUU9RhKH8K8/w3v1Gr5lyYQ0TZEkCYYELwrM53M5LL7jf1RC8Lqu0VHN7nb7ODntgS3Qnj+DdnwkWbE3TOCZ0oLAx2g02spkMJ+AW4YEFQyxsYJhQhdC9oyTJpMsy+RzMpmgbVvJluwIrvonAfd5Sj3JLIoi1Isay+VSSm2aFm3bYFKWmFcV6qZBEASy8FbyPkAGjczqZMae1U2Ny8sXWK/XWC1XcgHIkmBPMtwF5fTIkgw4HLKlVILzTCD2jt9te7gLsiuXT64fK7ubXnJg3W4Xvd7d8FQOzyx4sIe7RRRTgip5ajP43UFABSRoF9OUQKqHQRhI1gxKtDf7zpy9ku/dHo6DKAwRRSGqqpL2IBCBd4PFWPS/tnE3a8WBLBY1VquVtM/5+bmM6XQqAZq2ReD7cFz3vm0eMmTf1I6SHac7m82wXl9I67RNgyQZSsOrvh70oRoImy8vgyBAmmbysiUIC6hLgpJZnMAHp6xC6AM4Di8KD57L/Rb3bLNrn70+VFZIkwT5eIK0qJAVc2TjCmkxR5Kdyd1/SEResIckU65pu7D8DLafwvYzOEEO0wkxGGiP8riq/wDM8a2KmnVolwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9da3b2bbf5dbb535f01f93708d6616cc/e4706/stream_deck_interface.avif 230w", "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/d1af7/stream_deck_interface.avif 460w", "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/470bc/stream_deck_interface.avif 526w"],
              "sizes": "(max-width: 526px) 100vw, 526px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9da3b2bbf5dbb535f01f93708d6616cc/a0b58/stream_deck_interface.webp 230w", "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/bc10c/stream_deck_interface.webp 460w", "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/9752a/stream_deck_interface.webp 526w"],
              "sizes": "(max-width: 526px) 100vw, 526px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9da3b2bbf5dbb535f01f93708d6616cc/81c8e/stream_deck_interface.png 230w", "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/08a84/stream_deck_interface.png 460w", "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/2d7ab/stream_deck_interface.png 526w"],
              "sizes": "(max-width: 526px) 100vw, 526px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9da3b2bbf5dbb535f01f93708d6616cc/2d7ab/stream_deck_interface.png",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now add the RTSP Stream of your camera with your camera login, the camera IP or DDNS address, the streaming URL and RTSP Port. E.g. if your camera is configured as follows and you want to access the medium video stream:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera Username`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code></li>
      <li parentName="ul"><strong parentName="li">{`Camera Login`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`abcdefgh`}</code></li>
      <li parentName="ul"><strong parentName="li">{`IP Address`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`80.152.227.129`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Port`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`554`}</code>{` `}<em parentName="li">{`Standard Port`}</em></li>
      <li parentName="ul"><strong parentName="li">{`RTSP Streaming URL`}</strong>{`: 2k+ WQHD models `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/livestream/12`}</code>{` / Full HD & HD models `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/12`}</code></li>
    </ul>
    <p>{`You will have to use the following in both the `}<strong parentName="p">{`URL`}</strong>{` and `}<strong parentName="p">{`Parameter`}</strong>{` input field: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://admin:abcdefgh@80.152.227.129:554/livestream/12`}</code>{`. For debugging purposes it is recommended to try to `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`open your camera stream inside the VLC player`}</a>{` first.`}</p>
    <p>{`The `}<strong parentName="p">{`EXE Path`}</strong>{` con be pointed to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vlc.exe`}</code>{` to be able to access the full screen video. And the camera title can be choosen freely:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/276c58c76ecb1de4baefb3841a6545bf/0f98f/vivre_motion_ip_camera_white_intar0224.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABoLUFbNOw/8QAGxABAQACAwEAAAAAAAAAAAAAAgEAEgMREzH/2gAIAQEAAQUCa1nvTlA5s0Lj6Fnz/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EAB0QAAMAAQUBAAAAAAAAAAAAAAABEQIhIjFRgaH/2gAIAQEABj8CUVrhp9FnWr0TJU2pLw5P/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFRcYGR/9oACAEBAAE/IRfsDOoEqrfOVTWajAgDfsFDphGtlfZ//9oADAMBAAIAAwAAABC7D//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAARcf/aAAgBAgEBPxDUP//EABwQAQACAwEBAQAAAAAAAAAAAAERIQAxUYFBcf/aAAgBAQABPxAWhnpbFNGRvpMzbrvRPTBwIbKGWfZrzEAZCPwacEiZf1M87g6ReIz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/276c58c76ecb1de4baefb3841a6545bf/e4706/vivre_motion_ip_camera_white_intar0224.avif 230w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/d1af7/vivre_motion_ip_camera_white_intar0224.avif 460w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/7f308/vivre_motion_ip_camera_white_intar0224.avif 920w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/e1c99/vivre_motion_ip_camera_white_intar0224.avif 1380w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/76ea5/vivre_motion_ip_camera_white_intar0224.avif 1840w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/f696c/vivre_motion_ip_camera_white_intar0224.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/276c58c76ecb1de4baefb3841a6545bf/a0b58/vivre_motion_ip_camera_white_intar0224.webp 230w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/bc10c/vivre_motion_ip_camera_white_intar0224.webp 460w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/966d8/vivre_motion_ip_camera_white_intar0224.webp 920w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/445df/vivre_motion_ip_camera_white_intar0224.webp 1380w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/78de1/vivre_motion_ip_camera_white_intar0224.webp 1840w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/882b9/vivre_motion_ip_camera_white_intar0224.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/276c58c76ecb1de4baefb3841a6545bf/e83b4/vivre_motion_ip_camera_white_intar0224.jpg 230w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/e41a8/vivre_motion_ip_camera_white_intar0224.jpg 460w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/4d836/vivre_motion_ip_camera_white_intar0224.jpg 920w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/89b2d/vivre_motion_ip_camera_white_intar0224.jpg 1380w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/5726f/vivre_motion_ip_camera_white_intar0224.jpg 1840w", "/en/static/276c58c76ecb1de4baefb3841a6545bf/0f98f/vivre_motion_ip_camera_white_intar0224.jpg 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/276c58c76ecb1de4baefb3841a6545bf/4d836/vivre_motion_ip_camera_white_intar0224.jpg",
              "alt": "Stream Deck IP-CAMERA Plugin",
              "title": "Stream Deck IP-CAMERA Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      